import { Link } from "gatsby";
import React from "react";
import Button from "../../components/Button";
import "./VideoBanner.scss";

const VideoBanner = (props) => {
  return (
    // <div className="imageBanner" id="bgimg">
    <div className={`video-wrapper ${props.wrapClassName || ""}`} id="bgimg">
      <img
        className="imageBanner"
        src={props.VideobannerData.BannerImage}
        alt={props.VideobannerData.ImageBannerTitle}
        id="bgimg"
      />
      <div className="overlay">
        <div className="body-container">
          <div className="dynamic-bnr-img-title">
            {props.VideobannerData.ImageBannerTitle}
          </div>
          <div className="image-banner-sub-title">
            {props.VideobannerData.ImageBannerSubTitle}
          </div>
          {props.VideobannerData.displayButton !== "no" && (
            <div className="image-banner-btn">
              <Link
                target="_blank"
                style={{ display: "inline-block" }}
                className="services-link"
                to={props.VideobannerData.route}
              >
                <Button
                  customClassName="image-banner-resize-btn"
                  variant={props.VideobannerData.variant}
                  color={props.VideobannerData.color}
                  label={props.VideobannerData.label}
                  // routepath={props.VideobannerData.route}
                  tooltip={props.tooltip}
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoBanner;
